import api from '../../utils/api'
// 登录

export async function get_order (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         laboratories:{
  //           item_type: 1,
  //           items: [
  //             {
  //               item_id:1,
  //               item_name: 'CT头部扫描1',
  //               unique_id:1,
  //               item_desc: '条目介绍',
  //               enable: true
  //             },
  //             {
  //               item_id:2,
  //               item_name: 'CT头部扫描2',
  //               unique_id:2,
  //               item_desc: '条目介2',
  //               enable: true
  //             }
  //           ]
  //         },
  //         examines: {
  //           org_address: '深圳市罗湖区罗湖路',
  //           item_type: 2,
  //           items: [
  //             {
  //               item_id:1,
  //               item_name: 'CT头部扫描',
  //               reserved: true,
  //               reserved_time_section: '10:00-20:00',
  //               reserved_date: new Date('2021-10-20').getTime(),
  //               days: [
  //                 {
  //                   date:new Date('2021-10-20').getTime(),
  //                   time_sections: [
  //                     {
  //                       enable: false,
  //                       schedule_id: 1,
  //                       title: '8:00'
  //                     },
  //                     {
  //                       enable: true,
  //                       schedule_id: 2,
  //                       title: '10:00'
  //                     }
  //                   ]

  //                 },
  //                 {
  //                   date:new Date('2021-10-21').getTime(),
  //                   time_sections: [
  //                     {
  //                       enable: false,
  //                       schedule_id: 5,
  //                       title: '8:00'
  //                     },
  //                     {
  //                       enable: true,
  //                       schedule_id: 6,
  //                       title: '10:00'
  //                     }
  //                   ]

  //                 },
  //               ]
  //             },
  //             {
  //               item_id:2,
  //               item_name: '项目二',
  //               reserved: false,
  //               unique_id: 22,
  //               days: [
  //                 {
  //                   date:new Date().getTime(),
  //                   time_sections: [
  //                     {
  //                       enable: false,
  //                       schedule_id: 3,
  //                       title: '8:00-10:00'
  //                     },
  //                     {
  //                       enable: true,
  //                       schedule_id: 4,
  //                       title: '10:00-11:00'
  //                     }
  //                   ]

  //                 },
  //               ]
  //             },
  //           ]
  //         }

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/reserve/info/${payload.order_id}`,payload)
}
export async function submit_order (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     }, 500)
  //   })
  // })
  return api.post(`/api/v1/mp/reserve/examines`,payload)
}
export async function submit_inspection_order (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     }, 500)
  //   })
  // })
  return api.post(`/api/v1/mp/reserve/laboratories`,payload)
}

// 登录
export async function get_inspection_address (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         org_list: [
  //           {
  //             org_id: 1,
  //             org_name: '检测点一',
  //             short_name: '简称一',
  //             address: '地址一',
  //             latitude: '',
  //             longitude: '',
  //             link_phone: ''
  //           },
  //           {
  //             org_id: 2,
  //             org_name: '检测点2',
  //             short_name: '简称2',
  //             address: '地址2',
  //             latitude: '',
  //             longitude: '',
  //             link_phone: ''
  //           },
  //           {
  //             org_id: 3,
  //             org_name: '检测点3',
  //             short_name: '简称3',
  //             address: '地址3',
  //             latitude: '',
  //             longitude: '',
  //             link_phone: ''
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/laboratories/orgs`, {params: payload})
}
