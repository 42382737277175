<template>
  <div class="g_page_box box">
    <g-loading :loading="loading" />
    <div :class="['detail','g_main_content',{'order-detail':reservation_state === 1}]" >
      <div class="head">
        <div class="bg"/>
        <div class="text1">
          {{name}}
          <div>{{text}}</div>
        </div>
        <div class="process">
          <div v-for="(i,index) in list" :key="index" :class="['item',{'item-2': index===0},{active: i.value<=state}]">
            <div :class="['yuan',{'active-yuan': state>=i.value}]"/>
            <div :class="['line',{'active-line': state>=i.value}]"/>
            <div :class="{'label-position':index!==list.length-1&&index!==0}">{{i.label}}</div>
          </div>
        </div>
      </div>
      <!-- 检验预约流程 -->
      <ul class="time_list" style="padding-top: 10px;">
        <li v-for="(i,index) in weekArr" :key="index" class="select">
          <div class="week">{{i}}</div>
        </li>
      </ul>
      <ul class="time_list">
        <li v-for="(i,index) in dateList" :key="index" @click="select_time(i,index)" :class="[{'select':format_date(now_date) === format_date(i.date)},{'disable':(format_date(new Date()) !== format_date(i.date))&&new Date().getTime()>i.date}]">
          <div class="day">{{i.day}}</div>
        </li>
      </ul>
      <div class="down"><img width="11" height="11" src="../../assets/img/order/time-down.png" @click="show_time"/></div>
      <van-calendar color="#1989fa" :default-date="now_date" :min-date="minDate" v-model="show" @confirm="onConfirm" />
      <template v-if="item_type === 1&&reservation_state ===1">
        <div class="order_title">预约检验点</div>
        <div class="order_position" @click="select_address">
          <div>检测点</div>
          <div class="order_point s2" >{{org_name}}</div>
        </div>
        <div class="lab" style="margin:20px 0;">
           <ul class="select-lab">
            <li class="lab-title">
              <div class="circle"/>
              预约检验时间
            </li>
            <li class="reserves" style="padding-top: 10px;">
              <div v-for="(item,index) in time_list" :key="index" class="out-item">
                <div
                  @click="select_inspection_reserves(item)"
                  :class="['reserves-item',{'select-time': order_inspection_obj[format_date(now_date)]=== item.time_point},{'disable': hander_time(item)}]">
                  {{item.time_interval}}
                </div>
              </div>
            </li>

          </ul>
        </div>
        <div class="content" >
        <!-- 检验流程显示和预约提交之后流程 -->
          <div class="title-2">项目清单</div>
          <ul class="part-2">
            <li v-for="i in order_inspection_arr" :key="i.item_id" class="lab-i">
              <div class="name">
                {{i.item_name}}
                <div>{{i.item_desc}}</div>
              </div>
              <div :class="['state',{'finish-state':i.lab_report_state === 4}]">{{hander_state(i.lab_report_state)}}</div>
            </li>
          </ul>
      </div>
      </template>
      <!-- 检查预约流程 item_type（1:检验 2:检查）reservation_state（1-等待预约 2-等待检查 3-等待报告 4-完成:）-->
      <template v-if="item_type === 2&&reservation_state ===1">
        <div class="lab">
           <ul class="select-lab">
            <li class="lab-title">
              <div class="circle"/>
              {{order_obj.item_name}}
            </li>
            <li class="examination-address">{{org_address}}</li>

            <li class="reserves">
              <div v-for="(item,index) in order_obj.now_time" :key="index" class="out-item">
                <div @click="select_reserves(item)" :class="['reserves-item',{'select-time': order_obj.schedule_id === item.schedule_id},{'disable': !item.enable}]" >
                  {{item.title}}
                </div>
              </div>
            </li>

          </ul>
        </div>
        <div class="check">
          <div class="check-title">检查项目</div>
          <div class="check-item" v-for="(i,index) in order_arr" :key="index">
            <div class="name">
              <img width="16" v-if="i.reserved"  height="16" src="../../assets/img/order/order.png"/>
              <img v-else width="16" height="16" src="../../assets/img/order/no-order.png"/>
              <div class="text">
                <span>{{i.item_name}}</span>
                  <br/>
                  <template v-if="i.reserved">
                    {{org_address}} {{format_date(i.reserved_date)}} {{i.reserved_time_section}}
                  </template>
                  <template v-else>
                    未预约
                  </template>
              </div>
              <img v-if="!i.reserved" width="16" height="16" src="../../assets/img/order/wait.png"/>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="foot">
      <div :class="['foot-button',{'disable-button':(item_type===1 && (!org_id || !hander_poit())) || (item_type===2&&!order_obj.schedule_id)}]" @click="item_type ===1 ? set_inspection_order() : set_order()">{{item_type ===1 && order_len >0  ? '下一步' : '预约'}}</div>
    </div>
     <van-action-sheet
      v-model="address_show"
      cancel-text="取消"
      close-on-click-action
    >
    <div class="list" @click="comfire(i)" v-for="i in addrss_list" :key="i.org_id">
      {{i.org_name}}
    </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {submit_inspection_order,get_inspection_address,get_order,submit_order} from './service'
import {format_date} from '../../utils/format'
import { Dialog } from 'vant';
import {time_list} from './config'
export default {
  data () {
    const weekArr = new Array("日", "一", "二", "三", "四", "五", "六");
    return {
      order_id: '',
      item_type: null,
      loading: false,
      reservation_state: null,
      list: [],
      name: '',
      text: '',
      state: null,
      active: 0,

      // 所选日期的预约对象总和
      order_inspection_arr: [],
      data: {},
      org_name: '',
      org_id: '',
      address_show: false,
      addrss_list: [],
      // 时间
      order_arr: [],
      dateList:[], //存放日期的数组
      now_date: new Date(new Date().setHours(0,0,0,0)),
      show: false,
      minDate: new Date(new Date().setHours(0,0,0,0)),
      weekArr,
      // 所选日期的预约对象总和
      order_obj: {},
      org_address: '',
      time_list,
      order_inspection_obj:{}
    }
  },
  computed:{
    is_show() {
      let show = false
      if(this.item_type === 1) {
        for(let i of this.detail_data.items || []){
          if(i.lab_report_state&& i.lab_report_state===4){
            show = true
            break
          }
        }
      }
      return show
    },
    order_len() {
      return this.order_arr.filter(i=>{return !i.reserved}).length
    },
  },
  components: {

  },
  watch: {
    timestamp() {
      return this.format_date(this.now_date)
    },
    now_date(val) {
      if(this.item_type === 1) {
        this.order_inspection_obj[format_date(val)] = this.order_inspection_obj[format_date(val)] ? this.order_inspection_obj[format_date(val)] : ''
        return
      }
      let time = this.format_date(val)
      this.order_obj.now_time = this.order_obj.hours[time] || []
    },
    item_type() {
      this.hander_text()
    }
  },
  created() {
    let {order_id} = this.$route.query
    this.order_id = order_id
    this.search_order(1)
  },
  methods: {
    format_date,
    select_time(i) {
      this.now_date = new Date(i.date)
    },
    hander_time(item) {
      if(this.format_date(new Date()) !== this.format_date(this.now_date)) {
        return false
      }
      let time1 = new Date().getTime()
      let arr = item.time_interval.split('-')[1].split(':')
      let time2 = new Date().setHours(arr[0],arr[1])
      return time1>time2
    },
    hander_poit() {
      let time_point = ''
      for(let i of Object.values(this.order_inspection_obj)) {
        if(i) {
          time_point = i
          break
        }
      }
      return time_point
    },
    set_distance(val) {
      this.distance = val
    },
    hander_text() {
      let reservation_state = 2
      let name = this.item_type === 1 ? '检验' : '检查'
      this.name = name
      let text = ''
      let text1 = ''
      let list = []
      this.state = 1
      let arr = this.order_arr.filter(i=> {return !i.reserved})
      let arr1 = this.order_inspection_arr.filter(i=> {return i.enable})
      if(arr.length>0 && arr1.length>0) reservation_state = 1
      switch(+reservation_state) {
      case 1:
        text = `在线预约${name}时间和地点`
        text1 = `预约${name}`
        list = [{label:`预约检验`,value: 1},{label:`预约检查`,value: 2},{label:'智慧报告',value: 3}]
        break
      case 2:
        text = `在线预约${name}时间和地点`
        text1 = `预约${name}`
        list = [{label:`预约${name}`,value: 1},{label:'智慧报告',value: 3}]
        break
      default:
        text = ''
      }
      this.text = text
      this.name = text1
      this.list = list
    },
    hander_state(val) {
      let text = ''
      switch(val) {
      case 1:
        text = '等待预约'
        break
      case 2:
        text = '等待检查'
        break
      case 3:
        text = '等待报告'
        break
      case 4:
        text = '已出报告'
        break
      default:
        text = ''
      }
      return text
    },
    select_address() {
      this.address_show = true
    },
    comfire(i) {
      this.org_name = i.org_name
      this.org_id = i.org_id
      this.org_address = i.address
      this.address_show = false
    },
    async set_inspection_order() {
      try {
        this.loading = true
        const {org_name,org_id,order_id,org_address} = this
        let time_point = this.hander_poit()
        let list = time_point.split(':')
        let reserve_date = this.now_date.setHours(list[0],list[1])
        // console.log(format_date(reserve_date,'yyyy-MM-dd HH:mm:ss'),reserve_date)
        let items= []
        for(let i of this.order_inspection_arr) {
          items.push({
            unique_id: i.unique_id,
            order_id
          })
        }
        let params = {
          org_name,
          org_id,
          org_address,
          reserve_date,
          items,
        }
        await submit_inspection_order(params)
        let arr = this.order_arr.filter(i=>{return !i.reserved})
        if(arr.length>0) {
          this.item_type = 2
          this.state = 2
          return
        }
        this.$router.replace({path:'/order-success'})
      } finally  {
        this.loading = false
      }
    },
    async search_inspection_address() {
      let params = {
        order_id: this.order_id,
      }
      const {data} = await get_inspection_address(params)
      this.addrss_list = data.org_list
    },
    show_time() {
      this.show = true
    },
    select_reserves(item) {
      this.order_obj={...this.order_obj,schedule_id:item.schedule_id}
    },
    select_inspection_reserves(item) {
      for(let i in this.order_inspection_obj) {
        if(format_date(this.now_date) === i) {
          this.order_inspection_obj[i] = item.time_point
        } else {
          this.order_inspection_obj[i] = ''
        }
      }
      this.order_inspection_obj = {...this.order_inspection_obj}
    },
    async search_order (val) {
      let {item_type} = this.$route.query
      let nowDateTime = new Date().setHours(0,0,0,0)
      try {
        this.loading = true
        const {data} = await get_order({order_id:this.order_id})
        this.reservation_state = 1
        let examines = data.examines
        let items = data.laboratories&&data.laboratories.items || []
        items = items.filter(i=>{return i.enable})
        if(+item_type === 1 || (!item_type&&items.length>0&&val)) {
          this.order_inspection_obj[format_date(nowDateTime)] = ''
          this.item_type = 1
          this.order_inspection_arr = items
          this.search_inspection_address()
        }
        if(+item_type === 2 || !item_type) {
          if(val&&items.length === 0 || +item_type === 2) this.item_type = 2

          this.data = examines
          //模拟
          this.org_address = examines.org_address
          //模拟
          let has_data_time = null
          for(let i of examines.items) {
            if(i.reserved) continue
            i.hours = {}
            for(let item of i.days) {
              let date = this.format_date(item.date)
              if(!has_data_time&&item.time_sections&&item.time_sections.length>0) {
                has_data_time = item.date
              }
              i.hours[date] = item.time_sections
            }
          }
          // 获取有时间段的日期

          if(has_data_time) {
            nowDateTime = has_data_time
          }
          let time = this.format_date(nowDateTime)
          for(let i of examines.items) {
            if(!i.reserved) {
              i.schedule_id = ''
              i.now_time = i.hours[time] || []
              this.order_obj = i
              break
            }
          }
          this.order_arr = examines.items
        }
        this.now_date = new Date(nowDateTime)
        this.init_time(nowDateTime)
        if(val) this.hander_text()
      } finally  {
        this.loading = false
      }
    },
    async set_order() {
      try {
        this.loading = true
        let {item_id,schedule_id,unique_id} = this.order_obj
        let params = {
          sched_id: schedule_id,
          item_id,
          unique_id,
          order_id:this.order_id
        }

        await submit_order(params)
        let is_continue = false
        let item = null
        for(let i of this.order_arr) {
          if((i.item_id !== item_id) && (!i.reserved)) {
            is_continue = true
            item = i
          }
        }
        if(is_continue) {
          this.item_type = 2
          this.state = 2
          Dialog.alert({
            title: '预约成功',
            confirmButtonText: '继续',
            confirmButtonColor: '#0088FF',
            message: `请继续预约${item&&item.item_name}`,
          }).then(() => {
            this.search_order()
          });
          return
        }
        this.$router.replace({path:'/order-success'})
      } finally  {
        this.loading = false
      }
    },
    onConfirm(date) {
      this.show = false;
      this.now_date = date
      this.init_time(date.getTime())
    },
    init_time(nowDateTime) {
      const week = new Date(nowDateTime).getDay()
      let dateList = []
      for (let i = 0; i < week+1; i++) {
        let obj = this.getDateInfo(nowDateTime - i * 24 * 60 * 60 * 1000);
        dateList.unshift(obj)
      }
      for (let i = 1; i < 7-week; i++) {
        let obj = this.getDateInfo(nowDateTime + i * 24 * 60 * 60 * 1000);
        dateList.push(obj)
      }
      this.dateList = dateList
    },
    getDateInfo(ts) {
      const date = new Date(ts);
      return {
        date: ts,
        day: date.getDate()
      }
    },
  }
}
</script>
<style scoped>
.order-detail {
  background: #F7F7F7;
  padding-bottom: 50px;
}
.head {
  padding: 25px 23px 24px 23px;
  height: 166px;
  position: relative;
  background: #000CA8;
  display: flex;
  flex-direction: column;
  & .text1 {
    flex:1;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #FFFFFF;
    & >div {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #FFFFFF;
    }
  }
  & .bg {
    width: 106px;
    height: 100%;
    background: url('../../assets/img/order/detail-bg.png') center center no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  & .process {
    position: relative;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-left: 15px;
    & .item {
      position: relative;
      flex:1;
      color: #fff;
      text-align: right;

      & .label-position {
        display: inline-block;
        transform: translateX(25%)
      }
      & .yuan {
        position: absolute;
        top: -15px;
        right: 0px;
        z-index: 1;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 50%;
        &.active-yuan {
          background-color: rgba(255, 230, 35, 1);
        }
      }
      & .line {
        position: absolute;
        top: -15px;
        right: 0px;
        width: 100%;
        height: 2px;
        background-color:rgba(139, 149, 158, 1);
        &.active-line {
          background-color:rgba(255, 230, 35, 1);
        }
      }
      &.item-2{
        position: absolute;
        left: -15px;
       & .yuan {
          right: auto;
          left: 0;
       }
      }
      &.active{
        color: rgba(255, 230, 35, 1);
      }
    }
  }
}
.content {
  padding: 0px  20px 30px;
  margin-top: 20px;
  background: #fff;
  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #242938;
    padding-bottom: 14px;
  }
  & .part-1 {
    height: 130px;
    background: rgba(0, 0, 0, 0);
    border:1px solid #ddd;
  }
  & .title-2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #242938;
    padding: 12px 0 9px;
  }
  & .part-2 {
    & .lab-i {
      padding: 14px 17px;
      min-height: 65px;
      background: rgba(231, 241, 253, 0.44);
      border-radius: 10px;
      margin-bottom:14px;
      display: flex;
      align-items: center;
      & .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #242938;
          flex:1;
        & >div {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          color: #454545;
        }
      }
      & .state {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #0088FF;
        padding-left: 15px;
        & .finish-state {
          color: rgba(9, 187, 7, 1);
        }
      }
    }
  }
  & .part-3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #242938;
  }
}
.foot {
  height: 64px;
  background: #FFFFFF;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  padding-top: 11px;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  & .foot-button {
    background: #0088FF;
    height: 40px;
    font-size: 16px;
    border-radius: 9px;
    color: #FFFFFF;
    font-weight: 500;
    width: 335px;
    display: flex;
   justify-content: center;
   align-items: center;
   &.disable-button{
     opacity: 0.5;
     pointer-events: none;
   }
  }
}




.order_title {
  font-size: 18px;
  font-weight: 500;
  color: #565656;
  position: relative;
  padding-left: 20px;
  line-height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.order_position {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #565656;
  line-height: 46px;
  padding-left: 20px;
  background-color: #fff;
}

.order_point {
  font-weight: 400;
  position: relative;
  background-color: #fff;
  padding-right: 18px;
  display: flex;
  align-items: center;
  padding-right:40px;
  &.s2::after {
    content: "";
    position: absolute;
    margin-right: 20px;
    width: 6px;
    height: 6px;
    border: solid 1px #ccc;
    border-top: none;
    border-left: none;
    transform: rotate(-45deg);
    right: 0;
  }
}
.menu {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
}
.list {
  padding: 15px;
  font-size: 16px;
  text-align: center;
}
.time_list {
  display: flex;
  background: #fff;
  & li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #8B959E;
    & .day {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #242938;
      margin-top: 6px;
      width: 21px;
      height: 21px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    &.select{
      & .day {
        background: #0088FF;
        color: #FFFFFF;
      }
    }
    &.disable {
      pointer-events: none;
      & .day {
        color: #ddd;
      }
    }
  }
}
.down {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding-bottom: 8px;
}


.lab {
  margin: 20px 17px 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02);
  padding: 20px 21px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #8B959E;
  & .lab-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #242938;
    & .circle {
      width: 11px;
      height: 11px;
      background: #FFFFFF;
      border: 2px solid #0088FF;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  & .examination-address {
    padding:4px 0 16px;
  }
  & .reserves {
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px;
    margin: -5px;
    & .out-item{
      padding: 5px;
      width: 33.3%;
    }
     & .reserves-item {
        height: 42px;
        background: #FFFFFF;
        border: 1px solid rgba(139, 149, 158, 0.4);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.select-time {
          background: #0088FF;
          border: 1px solid #0088FF;
          color: #fff;
        }
        &.disable {
          border: 1px solid #8B959E;
          opacity: 0.16;
          pointer-events: none;
        }
        &.no-show {
          display: none;
        }
    }
  }

}
.check {
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02);
  margin: 23px 17px 0;
  padding: 23px 30px;
  & .check-title {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    color: #242938;
    padding-bottom: 20px;
  }
  & .check-item {
    margin-bottom: 27px;

  }
  & .name {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #707070;
    padding-bottom: 2px;
    & .text {
      flex: 1;
      padding-left: 12px;
      & span {
         font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        color: #242938;
      }
    }
    & img {
      transform: translateY(4px);
    }
  }
}
</style>
