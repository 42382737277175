export const time_list = [
  {
    time_interval: '8:00-9:00',
    time_point: '8:00',
  },
  {
    time_interval: '9:00-10:00',
    time_point: '9:00',
  },
  {
    time_interval: '10:00-11:00',
    time_point: '10:00',
  },
  {
    time_interval: '11:00-12:00',
    time_point: '11:00',
  },
  {
    time_interval: '12:00-13:00',
    time_point: '12:00',
  },
  {
    time_interval: '13:00-14:00',
    time_point: '13:00',
  },
  {
    time_interval: '14:00-15:00',
    time_point: '14:00',
  },
  {
    time_interval: '15:00-16:00',
    time_point: '15:00',
  }
]

